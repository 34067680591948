<template>
  <div>
    <v-card class="pt-1 mt-1" outlined>
      <v-card-title class="mb-n5">
        <p class="error--text text--darken-1">Profile Completion</p>
      </v-card-title>
      <v-divider class=""></v-divider>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <h4 class="caption">Company Details</h4>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                color="grey"
                append-icon="mdi-domain"
                label="Business Reg No *"
                outlined
                v-model="editedItem.reg_number"
                :rules="requiredRules"
              >
                <template v-slot:label v-if="!editedItem.reg_number">
                  <span class="input__label"
                    >Business Reg No
                    <v-icon small color="error" class="mt-n2"
                      >mdi-star-outline</v-icon
                    ></span
                  >
                </template>
                <template v-slot:label v-else>
                  <span class="input__label"
                    >Business Reg No
                    <v-icon small color="success">mdi-check</v-icon></span
                  >
                </template>
              </v-text-field>
              <v-text-field
                dense
                append-icon="mdi-phone"
                outlined
                color="grey"
                label="Company Phone"
                v-model="editedItem.phone_number"
                :rules="requiredRules"
              >
                <template v-slot:label v-if="!editedItem.phone_number">
                  <span class="input__label"
                    >Company Phone
                    <v-icon small color="error" class="mt-n2"
                      >mdi-star-outline</v-icon
                    ></span
                  >
                </template>
                <template v-slot:label v-else>
                  <span class="input__label"
                    >Company Phone
                    <v-icon small color="success">mdi-check</v-icon></span
                  >
                </template>
              </v-text-field>

              <v-text-field
                dense
                color="grey"
                append-icon="mdi-map-marker"
                outlined
                v-model="editedItem.physical_location"
                :rules="requiredRules"
              >
                <template v-slot:label v-if="!editedItem.physical_location">
                  <span class="input__label"
                    >Physical Location
                    <v-icon x-small color="error" class="mt-n2"
                      >mdi-star-outline</v-icon
                    ></span
                  >
                </template>
                <template v-slot:label v-else>
                  <span class="input__label"
                    >Physical Location
                    <v-icon small color="success">mdi-check</v-icon></span
                  >
                </template>
              </v-text-field>
              <v-select
                v-model="editedItem.business_type"
                :items="businessCategories"
                label="Select Category *"
                item-text="title"
                item-value="id"
                color="grey"
                dense
                outlined
                class="d-flex align-end"
                :rules="selectRules"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                dense
                color="grey"
                append-icon="mdi-web"
                outlined
                v-model="editedItem.website"
                :rules="requiredRules"
              >
                <template v-slot:label v-if="!editedItem.website">
                  <span class="input__label"
                    >Website
                    <v-icon small color="error" class="mt-n2"
                      >mdi-star-outline</v-icon
                    ></span
                  >
                </template>
                <template v-slot:label v-else>
                  <span class="input__label"
                    >Website
                    <v-icon small color="success">mdi-check</v-icon></span
                  >
                </template>
              </v-text-field>

              <!-- <v-file-input
                color="grey"
                append-icon="mdi-file-upload"
                prepend-icon=""
                outlined
                dense
                v-model="logoFileToBeUploaded"
                @change="handleLogoUpload"
              >
                <template v-slot:label v-if="!editedItem.company_logo">
                  <span class="input__label"
                    >Company Logo
                    <v-icon small color="error" class="mt-n2"
                      >mdi-star-outline</v-icon
                    ></span
                  >
                </template>
                <template v-slot:label v-else>
                  <span class="input__label"
                    >Company Logo
                    <v-icon small color="success">mdi-check</v-icon></span
                  >
                </template>
              </v-file-input> -->

              <div>
                <v-file-input
                  color="grey"
                  append-icon="mdi-file-upload"
                  prepend-icon=""
                  outlined
                  dense
                  style="display: none"
                  type="file"
                  ref="file"
                  :name="uploadFieldName"
                  @change="
                    onFileChange($event.target.name, $event.target.files)
                  "
                >
                  <template v-slot:label v-if="!editedItem.company_logo">
                    <span class="input__label"
                      >Company Logo
                      <v-icon small color="error" class="mt-n2"
                        >mdi-star-outline</v-icon
                      ></span
                    >
                  </template>
                  <template v-slot:label v-else>
                    <span class="input__label"
                      >Company Logo
                      <v-icon small color="success">mdi-check</v-icon></span
                    >
                  </template>
                </v-file-input>
                <v-row>
                  <v-col cols="6" md="6">
                    <div class="mx-auto text-center">
                      <image-input v-model="logoFileToBeUploaded">
                        <div slot="activator">
                          <v-avatar
                            size="100px"
                            v-ripple
                            v-if="!logoFileToBeUploaded.imageURL"
                            class="grey lighten-3 mb-3 border"
                          >
                            <span>Click to add logo</span>
                          </v-avatar>
                          <v-avatar
                            size="100px"
                            v-ripple
                            v-else
                            class="grey lighten-3"
                            style="border: 1px solid grey"
                            border
                          >
                            <v-img
                              max-height="100px"
                              max-width="100px"
                              v-model="logoFileToBeUploaded"
                              class="grey lighten-3"
                              rounded
                              :src="logoFileToBeUploaded.imageURL"
                              :lazy-src="logoFileToBeUploaded.imageURL"
                            ></v-img>
                          </v-avatar>
                        </div>
                      </image-input>
                      <v-slide-x-transition>
                        <div
                          v-if="logoFileToBeUploaded.imageURL != oldImageURL"
                          class="my-3"
                        >
                          <v-btn
                            color="primary"
                            dark
                            small
                            @click="handleLogoUpload"
                            :loading="loadingUpload"
                            >Upload</v-btn
                          >
                        </div>
                      </v-slide-x-transition>
                      <h4>Company logo</h4>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider class="mt-n4 mb-1"></v-divider>
      <v-card-actions>
        <!-- <v-btn
          color="grey darken-1"
          class="mr-auto mr-2 text--white text-capitalize"
          outlined
          >Skip <v-icon>mdi-skip-next</v-icon></v-btn
        >  -->
        <v-btn
          color="primary darken-1"
          class="ml-auto mr-2 text--white text-capitalize"
          @click="updateProfile"
          dark
          :loading="loading"
          >Update <v-icon>mdi-content-save-outline</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import BusinessTypeService from "../../services/business_type.service";
import ImageInput from "@/components/ImageInput.vue";

export default {
  data: () => ({
    avatar: null,
    oldImageURL: null,
    loadingUpload: false,
    saved: false,
    errorDialog: null,
    errorText: "",
    uploadFieldName: "file",
    maxSize: 1024,

    valid: true,
    businessCategories: [],
    selectedBusinessCategory: [],
    remember: 0,
    timeout: 2000,
    loading: false,
    logoFileToBeUploaded: {},
    editedItem: {},
    requiredRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    selectRules: [(v) => !!v || "Select is required"],
  }),

  props: {
    // Use "value" to enable using v-model
    value: Object,
  },
  name: "CompleteProfile",

  components: {
    ImageInput: ImageInput,
  },
  watch: {
    avatar: {
      handler: function () {
        this.saved = false;
      },
      deep: true,
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    loggedInUser() {
      return this.$store.state.auth.user;
    },
    user() {
      return this.$store.state.auth.user;
    },
    computedLogo() {
      return this.$store.state.auth.user.company.company_logo_url;
    },
    computedLogoFile() {
      return this.dataURLtoFile(
        this.$store.state.auth.user.company.company_logo,
        "logo"
      );
    },
  },
  mounted() {
    this.setDefault();
  },
  created() {
    console.log('Complete Profile Initiates')
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
    this.setDefault();
    this.getCategories();
  },
  methods: {
    getCategories() {
      return BusinessTypeService.index().then(
        (response) => {
          if (response.status == 200) {
            this.businessCategories = response.data.data;
          } else {
            this.businessCategories = [];
            console.log(response);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    setDefault() {
      this.editedItem.id = this.$store.state.auth.user.company.id;
      // this.editedItem.company_name = this.$store.state.auth.user.company.company_name;
      this.editedItem.physical_location = this.$store.state.auth.user.company.physical_location;
      this.editedItem.business_type = this.$store.state.auth.user.company.business_type;
      this.editedItem.email_address = this.$store.state.auth.user.company.email_address;
      this.editedItem.phone_number = this.$store.state.auth.user.company.phone_number;
      this.editedItem.website = this.$store.state.auth.user.company.website;
      this.editedItem.company_logo = this.user.company.company_logo;
      this.logoFileToBeUploaded.imageURL = "";
      this.logoFileToBeUploaded.imageURL = this.user.company.company_logo_url;
      this.oldImageURL = this.user.company.company_logo_url;
      this.editedItem.reg_number = this.$store.state.auth.user.company.reg_number;
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handleLogoUpload() {
      this.loadingUpload = true;
      let file = this.logoFileToBeUploaded.imageFile;
      let formData = {};
      formData["id"] = this.user.company.id;
      await this.getBase64(file).then((data) => (formData["logo"] = data));
      this.$store.dispatch("auth/updateCompanyLogo", formData).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", "Logo uploaded");
            this.oldImageURL = this.logoFileToBeUploaded.imageURL;
            this.loadingUpload = false;
          } else {
            this.$store.dispatch("alert/error", response.message);
            this.loadingUpload = false;
          }
        },
        (error) => {
          this.loadingUpload = false;
          if (error.response.status == 422) {
            console.log(error.response.data.errors);
            this.message = error.response.data.message;
            this.$store.dispatch("alert/error", this.message);
          } else {
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString() ||
              "Internal Server Error";
          }
          this.$store.dispatch("alert/error", this.message);
          this.loadingUpload = false;
        }
      );
    },

    readURL(file) {
      var reader = new FileReader();
      reader.onload = function (e) {
        localStorage.setItem("companylogo", e.target.result);
      };
      reader.readAsDataURL(file);
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    updateProfile() {
      this.valid = this.$refs.form.validate();
      this.editedItem.email_address = this.user.email;
      if(this.valid) {
        this.loading = true;
        this.$store.dispatch("auth/updateCompany", this.editedItem).then(
          (response) => {
            console.log(response);
            if (response.status == 200) {
              this.$store.dispatch("alert/success", "Profile updated");
              this.$router.push("marketplace");
              this.loading = false;
            } else {
              this.$store.dispatch("alert/error", response.message);
              this.loading = false;
            }
          },
          (error) => {
            this.loading = false;
            if (error.status == 422) {
              console.log(error.response.errors);
              this.message = error.response.errors[0].message;
              this.$store.dispatch("alert/error", this.message);
            } else {
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString() ||
                "Internal Server Error";
            }
            this.$store.dispatch("alert/error", this.message);
            this.loading = false;
          }
        );
      }
    },
  },
};
</script>
